import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import { graphql, PageProps } from "gatsby"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import NotFoundSection from "@components/NotFound"
import { NotFoundPartnersDataType } from "@typings/requests/404"

const NotFoundPage: React.FC<PageProps<NotFoundPartnersDataType>> = props => {
  const {
    allStrapiNotFoundPartnersPage: {
      nodes: [partnersNotFoundData],
    },
    allStrapiPageFooter: {
      nodes: [footerData],
    },
  } = props.data
  return (
    <>
      <SEO title="404: Not found" lang="es" />
      <ParallaxProvider>
        <LayoutContainer
          className="not-found-page"
          location={props.location}
          isPartnerSubdomain
          lang="es"
        >
          <NotFoundSection
            {...partnersNotFoundData}
            image={
              partnersNotFoundData.image.localFile.childImageSharp
                .gatsbyImageData
            }
            footerData={footerData}
          />
        </LayoutContainer>
      </ParallaxProvider>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  {
    allStrapiNotFoundPartnersPage(filter: { locale: { eq: "es" } }) {
      nodes {
        title
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 99, layout: FIXED, width: 1096)
            }
          }
        }
        errorButtonsRedirect: buttons {
          label
          url
          id
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`
